@font-face {
  font-family: 'Roboto Mono';
  src: url('../../../public/fonts/RobotoMono/Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto Mono';
  src: url('../../../public/fonts/RobotoMono/Medium.ttf');
  font-weight: 500;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
  border: 0 solid;
}

html {
  background-color: var(--background-01);
}

body {
  margin: 0;
  color: var(--white-01);
  font-family: var(--typography-font-family);
  font-size: var(--typography-14-medium-font-size);
  font-weight: var(--typography-14-medium-font-weight);
  line-height: inherit;
  // min-width: 320px;
  height: 100vh;
  -webkit-tap-highlight-color: transparent;

  &.overflow-hidden {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

h1 {
  font-size: var(--typography-22-medium-font-size);
}

h2 {
  font-size: var(--typography-18-medium-font-size);
}

h3 {
  font-size: var(--typography-16-medium-font-size);
}

h4 {
  font-size: var(--typography-14-medium-font-size);
}

h5 {
  font-size: var(--typography-12-medium-font-size);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: 500;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-weight: 500;
}

[data-rsbs-overlay] {
  border-radius: 0;
}

[data-rsbs-header],
[data-rsbs-scroll],
[data-rsbs-content] {
  border: 0;
}

[role='button'] {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.hide-horizontal-scroll {
  overflow-x: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.shepherd-element {
  z-index: 0;
}

@media screen and (max-width: 424px) {
  .shepherd-element {
    max-width: calc(100vw - 24px);
  }
}

.shepherd-content,
.shepherd-element {
  background: var(--secondary-04);
  color: var(--white-01);
  border-radius: 16px;
}

.shepherd-arrow {
  &:before {
    background-color: var(--secondary-04);
    height: 10px;
    position: absolute;
    width: 10px;
  }
}
.shepherd-element[data-popper-placement^='bottom'] > .shepherd-arrow {
  top: -5px;
}

.shepherd-element[data-popper-placement^='top'] > .shepherd-arrow {
  bottom: -10px;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: var(--secondary-04);
  padding: 0;
  margin-bottom: 8px;
  display: flex;
  gap: 4px;
  align-items: flex-start;
}
.shepherd-text {
  color: var(--white-01);
  padding: 0;
  font-size: var(--typography-14-regular-font-size);
  font-weight: var(--typography-14-regular-font-weight);
  line-height: var(--typography-14-regular-line-height);
}

.shepherd-title {
  color: var(--primary-01);
  flex: auto;
  font-size: var(--typography-16-medium-font-size);
  font-weight: var(--typography-16-medium-font-weight);
  line-height: var(--typography-16-medium-line-height);
}

.shepherd-enabled.shepherd-element {
  padding: 16px;
  transition: top 0.2s ease;
}

.shepherd-footer {
  margin-top: 12px;
  padding: 0;
  width: 100%;
}

.shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] > .shepherd-arrow:before {
  background-color: var(--secondary-04);
}

.shepherd-cancel-icon {
  display: flex;
  position: relative;
  top: -2px;
  span {
    transition: 0.25s ease;
    opacity: 0.4;
    color: var(--white-01);
    font-size: var(--typography-14-medium-font-size);
    font-weight: var(--typography-14-medium-font-weight);
    line-height: var(--typography-14-medium-line-height);
  }

  &:hover span {
    transition: 0.25s ease;
    opacity: 1;
  }
}

svg {
  transition: color 0.25s ease;
}

.grecaptcha-badge {
  display: none !important;
}

#chat-widget-container {
  display: none;
}
