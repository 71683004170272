.reverse-button {
  :hover {
    cursor: pointer;
  }
}

.round-button {
  cursor: pointer;
  transition: opacity, transform 0.3s;

  :hover {
    opacity: 0.9;
  }
}
