.select {
  --rh-select-bg: var(--secondary-02);
  --rh-select-color: var(--white-01);
  --rh-select-height: 40px;
  --rh-select-padding-left: 8px;
  --rh-select-padding-right: 12px;
  --rh-select-icon-color: var(--secondary-03);

  flex-direction: row;
  gap: 4px;

  background-color: var(--rh-select-bg);
  border-radius: 40px;
  color: var(--rh-select-color);
  cursor: pointer;
  display: inline-flex;
  height: var(--rh-select-height);
  align-items: center;
  user-select: none;
  padding: 0 var(--rh-select-padding-right) 0 var(--rh-select-padding-left);
  margin: 0;
  transition: all 200ms ease-in-out;
  font-size: var(--typography-14-medium-font-size);
}

.label {
  display: flex;
  margin: 0 4px 0 4px;
}

.icon {
  color: var(--rh-select-icon-color);
  font-size: 0;
}

.small {
  --rh-select-height: 32px;
}

.cardContent {
  color: var(--white-01);
  cursor: pointer;
  display: flex;
  padding: 16px 22px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.label {
  margin-right: auto;
}

.header {
  user-select: none;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tickers {
  overflow-x: scroll;
  display: flex;
  flex-wrap: wrap;
}

.assets {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 22px;
}

.modal {
  height: 100%;
  max-height: 100%;
}

.noResults {
  padding: 48px 0;
  justify-content: center;
  display: flex;
}

.inputContainer {
  display: flex;
  z-index: 1;
  height: 44px;
  width: 100%;
  box-sizing: border-box;
}

.input {
  left: -4px;
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid #20123f;
  border-radius: 40px;
  background: transparent;
  box-sizing: border-box;
  padding: 0 12px 0 48px;
  opacity: 0;
  pointer-events: none;
  font-size: 14px;

  &._active {
    opacity: 1;
    pointer-events: all;
    transition: all 0.2s ease;
    z-index: 0;
  }
}

.hidden {
  opacity: 0;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}
