.perpetuals-info {
  position: relative;
  width: 100%;
  height: 64px;
  z-index: 1;

  background: var(--background-01);

  border: 1px solid var(--secondary-02);
  border-radius: 12px;

  padding: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 68px;
}

.perpetuals-info-pair {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  cursor: pointer;

  & > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  & > svg {
    transition: all 0.2s ease;
  }

  & > svg.active {
    transform: rotate(180deg);
  }
}

.perpetuals-info-pair-text {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 2px;

  & > span:first-child {
    color: var(--white-01);
  }

  & > span {
    color: var(--gray-01);
    font-size: 22px;
    font-weight: 700;
  }
}

.perpetuals-info-stats {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 26px;

  & > span {
    min-width: 128px;
  }

  & > div {
    min-width: 128px;
  }

  & .green {
    color: #00a556;
  }

  & .red {
    color: var(--red-01);
  }
}

.perpetuals-info-pair-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 16px;
  padding: 12px 16px;

  cursor: pointer;
  transition: all 0.2s ease;

  &:not(.active) > svg {
    display: none;
  }

  &.active > svg {
    display: initial;
    color: var(--primary-01);
  }

  &.active > .perpetuals-info-pair-menu-item-token > .perpetuals-info-pair-menu-item-text > span {
    color: var(--primary-01);
  }

  &:hover {
    background-color: var(--secondary-02);
  }
}

.perpetuals-info-pair-menu-item-token {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 12px;

  & > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.perpetuals-info-pair-menu-item-text {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 2px;

  & > span:first-child {
    color: var(--white-01);
  }

  & > span {
    color: var(--gray-01);
    font-size: 16px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 767px) {
  .perpetuals-info {
    border: none;
  }
}

@media only screen and (max-width: 1376px) {
  .perpetuals-info-stats > div:nth-child(4) {
    display: none;
  }
}

@media only screen and (max-width: 1280px) {
  .perpetuals-info-stats > div:nth-child(4) {
    display: flex;
  }
}

@media only screen and (max-width: 936px) {
  .perpetuals-info-stats > div:nth-child(4) {
    display: none;
  }
}

@media only screen and (max-width: 782px) {
  .perpetuals-info-stats > div:nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: 612px) {
  .perpetuals-info-stats > div:nth-child(2) {
    display: none;
  }
}

@media only screen and (max-width: 462px) {
  .perpetuals-info-stats > div:nth-child(1) {
    display: none;
  }
}
