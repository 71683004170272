.animated {
  transition: transform 200ms ease, color 10ms ease, background-color 100ms ease;
}

.hoverable-active {
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: rgba(134, 77, 247, 0.5) !important;
}

.hoverable {
  cursor: pointer !important;
  &:hover:not(.hoverable-active) {
    color: var(--secondary-03) !important;
    background-color: rgba(134, 77, 247, 0.2) !important;
  }
}

.animated:not(.hoverable) {
  transition: transform 200ms ease, color 200ms ease, background-color 500ms ease;

  &:active {
    color: var(--secondary-03) !important;
    background-color: var(--secondary-04) !important;
  }
}

.dashboard-container:not(.widget) {
  @media only screen and (max-width: 767px) {
    padding-bottom: 80px;
  }
}

.filter-container {
  padding-left: 16px;
  padding-right: 16px;

  @media only screen and (max-width: 767px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}
