.checkbox {
  user-select: none;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 8px;
}

.checkbox.disabled {
  cursor: default;
  pointer-events: none;
}

.checkbox-icon-wrapper {
  display: inline-flex;
  align-items: center;
}

.checkbox .checkbox-icon {
  font-size: 1.25rem;
  color: var(--main-brand-green);
  margin-bottom: 0;
}

.checkbox.fullRow .checkbox-icon {
  margin-right: 0;
}

.checkbox.disabled .checkbox-icon {
  color: var(--main-brand-green-transparent);
}

.checkbox-icon.inactive {
  color: var(--additional-light-gray);
}

.checkbox-label {
  color: var(--additional-light-gray);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.checkbox.fullRow {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}
