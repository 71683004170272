.perpetuals-form-balance {
  width: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 8px;

  & > div {
    width: 100%;
  }
}

.perpetuals-form-balance-buttons {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 8px;

  & > .perpetuals-form-balance-button {
    height: 32px;

    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;

    border-radius: 8px;

    & > span {
      font-size: var(--typography-app-12-medium-font-size);
      font-weight: var(--typography-app-12-medium-font-weight);
    }
  }
}
