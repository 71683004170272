.perpetuals-lists-tabs {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 16px;
}

.perpetuals-lists-tab {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 4px;

  cursor: pointer;
  transition: all 0.2s ease;

  &.active > span {
    color: var(--primary-01);
  }

  &.active > .perpetuals-lists-tab-badge {
    background: var(--primary-01);
    color: var(--background-01);
  }

  &:not(.active) > span {
    color: var(--secondary-03);
  }
}

.perpetuals-lists-tab-badge {
  min-width: 16px;
  height: 16px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  border-radius: 16px;
  padding-left: 2px;
  padding-right: 2px;
  background: var(--secondary-03);

  font-size: var(--typography-12-medium-font-size);
  font-weight: var(--typography-12-medium-font-weight);

  transition: all 0.2s ease;
}
