.trigger {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 8px;
  border-radius: 40px;
  border-color: var(--secondary-02);
  border-width: 1px;
  background: var(--background-01);

  transition: all 0.2s ease;

  &.open {
    background: var(--secondary-04);
  }

  @media (hover: hover) {
    &:hover:not(.open) {
      border-color: var(--secondary-01);
    }
  }
}
