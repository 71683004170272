.root {
  --header-height: 65px;
  --inner-padding: 32px;
  --outer-padding: 24px;

  @media screen and (max-height: 715px) and (max-width: 767px) {
    padding-bottom: 128px;
  }

  @media screen and (max-height: 715px) {
    @media screen and (min-width: 768px) {
      max-height: calc(100vh - var(--header-height) - var(--inner-padding) - var(--outer-padding) - 8px);
      overflow-y: scroll;
      padding-bottom: 48px;
    }
    @media screen and (min-width: 991px) {
      --inner-padding: 42px;
    }
  }
}

.hide-submit-button {
  padding-bottom: 0;
}

.root,
.amount {
  @media screen and (max-height: 715px) {
    gap: 6px;
  }
}

.amount {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 4px;

  @media screen and (max-height: 715px) {
    margin-top: 6px;
  }
}
