.card {
  border-width: 1px;
  border-style: solid;
  border-radius: 16px;
  position: relative;
  transition: all 0.1s ease-in-out;
}

.label {
  border-radius: 16px;
  padding: 0 8px;
  position: absolute;
  transform: translateY(-50%) translateX(16px);
}
