.perpetuals-form-balance-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  gap: 16px;

  width: 100%;
  height: 56px;

  border: 1px solid var(--secondary-02);
  border-radius: 16px;

  transition: all 0.2s ease;

  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &.active {
    border: 1px solid var(--secondary-03);
  }

  & > svg {
    transition: all 0.2s ease;
  }

  &.active > svg {
    transform: rotate(180deg);
  }
}

.perpetuals-form-balance-selector-token {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: fit-content;
}

.perpetuals-form-balance-selector-token-img {
  width: 32px;
  height: 32px;

  border-radius: 50%;
}

.perpetuals-form-balance-selector-token-info {
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

.perpetuals-form-balance-selector-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 12px;

  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background: var(--secondary-02);
  }

  &.active > div .perpetuals-form-balance-selector-item-info > span {
    color: var(--primary-01);
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  &:not(.active) > svg {
    display: none;
  }
}

.perpetuals-form-balance-selector-item-img {
  width: 20px;
  height: 20px;

  border-radius: 50%;
}

.perpetuals-form-balance-selector-item-info {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
