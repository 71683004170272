.logo {
  --rh-logo-color: var(--white-01);

  fill: var(--rh-logo-color);
}

.primary {
  --rh-logo-color: var(--primary-01);
}

.inverted {
  --rh-logo-color: var(--background-01);
}
