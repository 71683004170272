.assets {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 22px;
}

.noResults {
  padding: 48px 0;
  justify-content: center;
  display: flex;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;

  &:nth-child(2) {
    padding-top: 0;
  }

  &:nth-last-child(2) {
    padding-bottom: 0;
  }
}

.table-item {
  cursor: pointer;
  content-visibility: hidden;
  transition: all 0.2s ease;

  &:hover {
    background-color: var(--secondary-02);
  }

  &:hover .table-item-hoverable {
    background-color: var(--secondary-02);
    border-color: var(--secondary-02);
  }
}

.table-item-hoverable {
  transition: all 0.2s ease;

  background-color: var(--background-01);
  border-color: var(--background-01);

  & .table-item:hover {
    background-color: var(--secondary-02);
    border-color: var(--secondary-02);
  }
}

.border-separated {
  border-bottom: 1px solid var(--secondary-02);
}

.table-container {
  width: 100%;

  border-collapse: collapse;

  @media only screen and (max-width: 425px) {
    width: 100%;

    margin-left: 0px;
    margin-right: 0px;
  }
}

.table-head-cell {
  padding: 12px 0px 16px 0px;

  text-align: start;
}

.table-column-spacing {
  width: 16px;

  @media only screen and (max-width: 767px) {
    width: 12px;
  }
}
