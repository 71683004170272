.text-container > a {
  color: var(--primary-01);
  transition: all 0.2s ease;
}

.text-container > a:hover {
  color: #8fffca;
}

.spinner {
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
