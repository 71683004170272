.assets {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 22px;
}

.noResults {
  padding: 48px 0;
  justify-content: center;
  display: flex;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;

  &:nth-child(2) {
    padding-top: 0;
  }

  &:nth-last-child(2) {
    padding-bottom: 0;
  }
}

.list-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  &:hover:not(.item-header) {
    background-color: var(--secondary-02);
    cursor: pointer;
  }

  &:hover .list-item-hoverable {
    background-color: var(--secondary-02);
    border-color: var(--secondary-02);
    cursor: pointer;
  }

  @media only screen and (max-width: 767px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.list-item-hoverable {
  width: 50%;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  background-color: var(--background-01);
  border-color: var(--background-01);

  & .list-item:hover {
    background-color: var(--secondary-02);
    border-color: var(--secondary-02);
  }
}

.list-container {
  width: 100%;

  border-collapse: collapse;

  @media only screen and (max-width: 425px) {
    width: 100%;

    margin-left: 0px;
    margin-right: 0px;
  }
}

.list-head-cell {
  padding: 12px 0px 16px 0px;

  text-align: center;

  &:nth-child(1) {
    text-align: start !important;
  }

  &:nth-last-child(1) {
    text-align: end !important;
  }
}

.list-column-spacing {
  width: 16px;

  @media only screen and (max-width: 767px) {
    width: 12px;
  }
}
