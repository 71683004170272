.input {
  background-color: transparent;
  border: none;
  color: var(--white-01);
  display: inline-flex;
  margin-right: 16px;
  outline: none;
  font-size: var(--typography-26-medium-font-size);
  flex-grow: 1;
  min-width: 40px;
  opacity: 1 !important;

  &:disabled {
    color: var(--white-01) !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    opacity: 1 !important;
  }
}
