#walletconnect-wrapper {
  color: black;
}

.triangle-up:before {
  content: '';
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 4px solid var(--red-01);
}
