.root {
  z-index: 10;
  animation: close 175ms ease both;
  transform-origin: 0 0;
  transition: opacity 175ms ease;

  @media screen and (max-width: 575px) {
    animation: unset;
    transition: unset;
  }
}

.root.mobile {
  position: fixed;
  left: 0;
  top: 0;
}

.container {
  position: relative;
  .block:not(.hidden) {
    display: block;
  }
}

.block {
  display: none;
}

.hidden {
  display: none;
}

.root.open {
  animation: open 175ms ease both;

  @media screen and (max-width: 575px) {
    animation: unset;
  }
}

@keyframes open {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes close {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
  }
}
