.language-button {
  transition: all 0.2s ease;

  &:hover {
    background-color: #20123f;
  }
}

.language-grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0;

  max-height: 224px;
}

.language-btn {
  height: 56px;
  position: relative;
  padding: 16px 24px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 8px;
  transition: background-color 0.2s ease;

  outline: 0.5px solid #20123f;
  flex: 1 0 56px;
  width: 33.5%;
  box-sizing: border-box;
}

.language-btn.active {
  color: #05f283;
  background-color: #20123f;
}

@media screen and (hover: hover) {
  .language-btn:hover:not(.active) {
    color: #864df7;
    background-color: #20123f;
  }
}
