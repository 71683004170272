.root {
  opacity: 0;
  width: max-content;
  max-width: 300px;
  background-color: #301761;
  color: #fff;
  font-size: 90%;
  padding: 8px 12px;
  border-radius: 8px;
  z-index: 1;
  transition-property: opacity;
  transition-duration: 250ms;
}

.root[data-status='open'] {
  opacity: 1;
}

.root[data-status='initial'],
.root[data-status='close'] {
  opacity: 0;
}

.trigger {
  display: inline-block;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  width: 18px;
  height: 18px;
  border: 1px solid #fff;
  border-radius: 12px;
  vertical-align: baseline;
  line-height: 16px;
  text-align: center;
  position: relative;
  top: -1px;
  margin-left: 8px;
}

.arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #301761;
  bottom: -5px;
  transform: rotate(45deg);
}
