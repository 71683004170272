.perpetuals-form-amount {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;

  padding-bottom: 5px;

  & > span:first-child {
    font-weight: 400;
  }
}

.perpetuals-form-amount-input {
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 10px;

  & > div:first-child {
    flex-basis: 0;
    flex-grow: 2;
    flex-shrink: 0;
  }

  & > div {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.perpetuals-form-amount-input-field {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;

  width: fit-content;

  border: 1px solid var(--secondary-02);
  border-radius: 16px;

  transition: all 0.2s ease;

  & > input {
    background-color: transparent;
    border: none;
    color: var(--white-01);
    display: inline-flex;
    outline: none;
    font-size: var(--typography-16-medium-font-size);
    font-weight: var(--typography-16-medium-font-weight);
    flex-grow: 1;
    min-width: 40px;
    caret-color: var(--primary-01);

    transition: all 0.2s ease;
  }

  &.error,
  &.error:focus-within {
    border: 1px solid var(--red-01);
  }

  &:focus-within {
    border: 1px solid var(--primary-01);
  }
}

.perpetuals-form-amount-input-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;

  width: fit-content;

  border: 1px solid var(--secondary-02);
  border-radius: 16px;

  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: var(--secondary-02);
  }
}

.perpetuals-form-amount-slider {
  width: 100%;

  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  & > .rc-slider {
    border-radius: 6px;
    height: 14px;
    padding: 5px 0;
    position: relative;
    touch-action: none;
    width: 100%;
  }
}
