.chains-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: var(--secondary-02);
  border-radius: 32px;
  padding-left: 4px;
  padding-right: 4px;
}

.chains-btn-all {
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  color: var(--secondary-03);
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 2px;
}

.chains-btn-all-active {
  border: 1px solid var(--secondary-03);
}

.chains-btn-expand {
  height: 22px;
  width: 22px;
  align-items: center;
  justify-content: center;
  color: var(--secondary-03);
  background-color: var(--secondary-04);
  border-radius: 24px;
}
