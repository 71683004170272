.cardContent {
  display: flex;
  padding: 12px 16px 12px 24px;
  align-items: center;
  justify-content: space-between;
}

.input {
  background-color: transparent;
  border: none;
  color: var(--white-01);
  display: inline-flex;
  margin-right: 16px;
  outline: none;
  font-size: var(--typography-22-medium-font-size);
  flex-grow: 1;
  min-width: 40px;
}
