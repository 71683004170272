.tickers {
  overflow-x: scroll;
  display: flex;
  scroll-behavior: smooth;
}

.hidden {
  opacity: 0 !important;
  z-index: -1 !important;
}

.scroll-button {
  height: 22px;
  width: 22px;

  position: absolute;
  top: 50%;
  z-index: 1;

  align-items: center;
  justify-content: center;

  opacity: 1;
  color: var(--secondary-03);
  background-color: var(--secondary-04);

  border-radius: 24px;
  box-shadow: -10px 0px 10px black;

  transition: opacity 100ms ease-out, z-index 150ms ease-out;
}

.right-button {
  right: -2px;
  transform: translateY(-50%);
}

.left-button {
  left: -2px;
  transform: rotate(180deg) translateY(50%);
}

.animated-ticker {
  cursor: pointer;
  transition: all 200ms ease-out;
}

.hoverable-ticker {
  &:hover {
    color: var(--primary-01-disabled);
  }
}

.scroll-hoverable {
  cursor: pointer !important;
  &:hover:not(.hoverable-active) {
    color: var(--secondary-03) !important;
    background-color: var(--secondary-02) !important;
  }
}
