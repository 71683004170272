.perpetuals-lists-positions {
  width: 100%;
  min-width: 900px;

  display: table;
  border-collapse: collapse;
  table-layout: fixed;
}

.perpetuals-lists-positions-header {
  width: 100%;

  display: table-header-group;

  & > .perpetuals-lists-positions-row {
    border-top: 1px solid var(--secondary-02);
    border-bottom: 1px solid var(--secondary-02);
  }

  & > .perpetuals-lists-positions-row > .perpetuals-lists-positions-cell {
    height: 50px;
  }
}

.perpetuals-lists-positions-body {
  width: 100%;

  display: table-row-group;
}

.perpetuals-lists-positions-row {
  width: 100%;
  display: table-row;
  z-index: auto;

  border-bottom: 1px solid var(--secondary-02);
}

.perpetuals-lists-positions-cell {
  height: 62px;
  display: table-cell;
  align-content: center;
  vertical-align: middle;

  & > div {
    min-width: max-content;

    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding: 12px 0px;
    padding-right: 16px;

    gap: 4px;
  }

  &:last-child > div {
    width: 72px;
    align-items: flex-end;
    padding-right: 0;
  }
}

.perpetuals-lists-positions-cell-token {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 4px;

  & > img {
    width: 20px;
    height: 20px;

    border-radius: 50%;
  }
}

.perpetuals-lists-positions-cell-leverage {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 4px;
}

.perpetuals-lists-positions-cell-pnl {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: 4px;

  & > img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.perpetuals-lists-positions-positive {
  color: #00a556;
}

.perpetuals-lists-positions-negative {
  color: var(--red-01);
}

.perpetuals-lists-positions-small-cell {
  flex-grow: 0.8;
}

.perpetuals-lists-positions-big-cell {
  flex-grow: 1.5;
}
