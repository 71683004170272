.nav-tabs {
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 767px) {
    gap: 4px;
  }
}

.nav-tabs-mobile {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  width: 100vw;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  & > div {
    overflow: hidden;
    overflow-x: auto;
  }

  @media screen and (max-width: 767px) {
    gap: 4px;
  }
}

.nav-tab-item {
  padding: 8px;
  transition: all 0.2s ease;

  :hover {
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 0;

    padding-top: 12px;
    padding-bottom: 14px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }

  @media screen and (hover: hover) {
    &:hover:not(.active) > svg {
      color: #8fffca;
    }
    &:hover:not(.active) > span {
      color: #8fffca;
    }
  }
}
