.perpetuals-form-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 24px;

  padding: 20px 16px 24px 16px;
}

.perpetuals-form-modal-token {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 16px;
  padding: 14px 16px;
  border-radius: 16px;

  background: var(--secondary-02);
  transition: all 0.2s ease;

  cursor: pointer;

  & > svg {
    transform: rotate(-90deg);
  }

  &:hover {
    background: var(--secondary-04);
  }
}

.perpetuals-form-modal-token-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
}

.perpetuals-form-modal-token-image {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  & > img:first-child {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  & > img:last-child {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: -8px;
    background: var(--background-01);
  }
}

.perpetuals-form-modal-token-badge {
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #3c2870;

  padding: 2px 6px;
  border-radius: 8px;
}

.perpetuals-form-modal-input {
  width: 100%;
  margin-top: 4px;
}

.perpetuals-form-modal-balance-container {
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 2px;
}

.perpetuals-form-modal-balance-top {
  width: 100%;
  z-index: 1;

  padding: 10px 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: var(--secondary-02);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;

  & > button {
    width: 36px;
    height: 32px;
    margin-top: 1.5px;
    border: none;
  }
}

.perpetuals-form-modal-balance-bottom {
  width: 100%;

  padding: 10px 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: var(--secondary-02);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.perpetuals-form-modal-balance-info {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;

  gap: 8px;
}

.perpetuals-form-modal-balance-image {
  width: 32px;
  height: 32px;
  margin-top: 1.5px;
}

.perpetuals-form-modal-balance-texts {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  gap: 2px;
}

.perpetuals-form-modal-balance-text {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  gap: 4px;

  & > div {
    width: fit-content;
  }

  & > span {
    flex-basis: max-content;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    white-space: nowrap;
  }
}

.perpetuals-form-modal-balance-down {
  z-index: 1;
  position: absolute;
  right: 8px;
  bottom: -16px;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--background-01);

  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 16px;
    height: 16px;
    transform: rotate(90deg);
    color: var(--gray-01);
  }
}
