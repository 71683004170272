.select {
  --rh-select-bg: var(--secondary-02);
  --rh-select-color: var(--white-01);
  --rh-select-height: 40px;
  --rh-select-padding-left: 8px;
  --rh-select-padding-right: 12px;
  --rh-select-icon-color: var(--secondary-03);

  background-color: var(--rh-select-bg);
  border-radius: 40px;
  color: var(--rh-select-color);
  cursor: pointer;
  display: inline-flex;
  height: var(--rh-select-height);
  align-items: center;
  user-select: none;
  padding: 0 var(--rh-select-padding-right) 0 var(--rh-select-padding-left);
  margin: 0;
  transition: all 200ms ease-in-out;
  font-size: var(--typography-14-medium-font-size);
}

.label {
  display: flex;
  margin: 0 4px 0 8px;
}

.icon {
  color: var(--rh-select-icon-color);
  font-size: 0;
}

.small {
  --rh-select-height: 32px;
}
