.container {
  width: 100%;
  height: 44px;
  position: relative;
  z-index: 2;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 10px;
}

.inputContainer {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 44px;
  width: 100%;
  box-sizing: border-box;
}

.icon-button {
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 2px;
  left: 0px;
  color: var(--secondary-03);
  background-color: var(--secondary-02);
  border-radius: 42px;
  transition: all 1.2s ease-in-out;

  &._active {
    left: 2px;
    width: 40px;
    height: 40px;
  }
}

.input {
  left: 0px;
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid #20123f;
  border-radius: 40px;
  background: transparent;
  box-sizing: border-box;
  padding: 0 12px 0 48px;
  opacity: 0;
  pointer-events: none;
  font-size: 16px;
  transition: all 0.2s ease;

  &._active {
    opacity: 1;
    pointer-events: all;
    z-index: 0;
  }
}

.hidden {
  opacity: 0;
}
