.container {
  min-height: 208px;
  height: 208px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px solid var(--secondary-02);
  border-radius: 16px;
}

.infoLabel {
  color: var(--secondary-01);
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
}

.infoLeft,
.infoRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 1rem;
}
.infoLeft {
  padding-top: 0.875rem;
}
.infoRight {
  padding-top: 0.675rem;
}

.infoLeft {
  justify-content: space-between;
}

.infoLeftUp,
.infoLeftDown {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.infoLeftUp {
  align-items: flex-start;
  justify-content: flex-end;
  padding-bottom: 8px;
  gap: 4px;
}

.infoLeftDown {
  padding-bottom: 16px;
  text-align: center;
}

.entryPriceLabel {
  font-size: var(--typography-12-medium-font-size);
  font-weight: 400;
}

.entryPriceValue {
  display: inline-flex;
  font-size: var(--typography-12-medium-font-size);
  font-weight: 400;
  justify-content: center;
  align-items: center;
  background-color: var(--white-01);
  border-radius: 20px;
  color: var(--background-01);
  padding: 0 6px;
}

.infoRightUp,
.infoRightDown {
  display: flex;
  flex-direction: column;
  padding: 0 28px;
  position: relative;
  justify-content: center;
}
.infoRightUp {
  margin-bottom: 0.95rem;

  & > .profitCurrency {
    transform: translateX(-20px) translateY(-60%);
  }
}

.profitCurrency {
  background-color: var(--background-01);
  border: 4px solid var(--background-01);
  border-radius: 50%;
  font-size: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-20px) translateY(-45%);
}

.profitText,
.totalText {
  font-size: var(--typography-12-medium-font-size);
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 0.5rem;
}

.profitText {
  color: var(--primary-01);
}
