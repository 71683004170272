.perpetuals-chart {
  width: 100%;
  height: calc(100vh - 388px);
  min-height: 520px;

  background: var(--background-01);

  border: 1px solid var(--secondary-02);
  border-radius: 12px;

  padding: 20px;
  padding-left: 0px;
  padding-right: 8px;
}

.perpetuals-chart-content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}

.perpetuals-chart-tv {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1280px) {
  .perpetuals-chart {
    height: 520px;
    min-height: 520px;
  }
}

@media only screen and (max-width: 767px) {
  .perpetuals-chart {
    border: none;

    padding-top: 0;
    padding-bottom: 0;
    padding-left: 12px;
  }

  .perpetuals-chart {
    height: 358px;
    min-height: 358px;
  }
}
