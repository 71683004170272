.perpetuals-lists {
  width: 100%;
  min-height: 174px;

  background: var(--background-01);

  border: 1px solid var(--secondary-02);
  border-radius: 12px;

  padding: 20px;

  gap: 8px;
}

.perpetuals-lists-header {
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  padding-bottom: 12px;
}

.perpetuals-lists-content {
  overflow: hidden;
  overflow-x: auto;
  scrollbar-color: var(--secondary-02) transparent;
  scrollbar-width: thin;
  padding-bottom: 16px;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .perpetuals-lists {
    border: none;
  }
}
