.select {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  width: 100%;
  cursor: pointer;
  scroll-snap-align: start;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.cardContent {
  padding: 0.75rem;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
