.modal {
  // timeout must be the same as closeTimeoutMS prop in <ReactModal />
  animation: close 400ms linear both;
  inset: auto;
}

.modal.open {
  animation: open 250ms ease both;
}

.stickyTop {
  position: absolute;
  top: 5vh;
  bottom: unset;
}

@keyframes open {
  from {
    opacity: 0.5;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes close {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.85);
  }
}
