.perpetuals-form-switch {
  width: 100%;

  display: flex;
  flex-direction: row;

  gap: 2px;

  & > .perpetuals-form-switch-button {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
  }

  & > .perpetuals-form-switch-button:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    align-items: flex-start;

    &.active {
      background: #00a556;
    }
  }

  & > .perpetuals-form-switch-button:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    align-items: flex-end;

    &.active {
      background: var(--red-01);
    }
  }
}

.perpetuals-form-switch-button {
  display: flex;
  flex-direction: column;
  gap: 2px;

  justify-content: center;

  background: var(--secondary-02);
  padding: 12px 16px;

  font-size: var(--typography-16-medium-font-size);
  font-weight: var(--typography-16-medium-font-weight);

  transition: all 0.2s ease;

  &:hover:not(.active):first-child {
    cursor: pointer;
    background: #096641;
  }

  &:hover:not(.active):last-child {
    cursor: pointer;
    background: #be3333;
  }
}

.perpetuals-form-switch-button-markup {
  display: flex;
  flex-direction: row;
  gap: 8px;

  align-items: center;
  justify-content: center;

  font-size: var(--typography-16-medium-font-size);
  font-weight: var(--typography-16-medium-font-weight);

  transition: all 0.2s ease;
}

.perpetuals-form-switch-spread {
  height: 20px;

  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;

  background: white;
  border-radius: 20px;
}
