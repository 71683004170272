.reward-item-wrapper::before {
  content: '';
  position: absolute;
  left: 28px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: var(--secondary-04);
}

.reward-item {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
}

.reward-icon {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  margin-right: 20px;
  background-color: var(--secondary-04);
  color: var(--primary-01);
  stroke: var(--primary-01);
  stroke-width: 0;
  display: 'flex';
  align-items: center;
  justify-content: center;
}

.reward-button {
  width: 260px;
  border: none;
  margin-right: auto;

  @media only screen and (max-width: 425px) {
    width: 100%;
  }
}
