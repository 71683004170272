.perpetuals-netvalue-tooltip-handle {
  border-bottom: 1px dashed hsla(0, 0%, 100%, 0.4);

  transition: all 0.2s ease;

  &:hover {
    border-bottom: 1px dashed hsla(0, 0%, 100%, 0.8);
    cursor: help;
  }

  &:hover > span {
    color: white !important;
  }
}
