.trigger {
  border-color: var(--secondary-02);
  background: var(--background-01);

  transition: all 0.2s ease;

  &.open {
    background: var(--secondary-04);
  }

  @media (hover: hover) {
    &:hover:not(.open) {
      border-color: var(--secondary-01);
    }
  }
}

.menu-item {
  transition: all 0.2s ease;

  &:hover {
    background-color: #20123f;
  }
}
