.perpetuals-modal-token-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px 0px 24px 0px;
}

.perpetuals-modal-token-select-token {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 16px;
  padding: 14px 16px;

  transition: all 0.2s ease;

  cursor: pointer;

  & > svg {
    opacity: 1;
  }

  &:hover {
    background: var(--secondary-02);
  }
}

.perpetuals-modal-token-select-token-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
}

.perpetuals-modal-token-select-token-image {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  & > img:first-child {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  & > img:last-child {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: -8px;
    background: var(--background-01);
  }
}

.perpetuals-modal-token-select-token-badge {
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #3c2870;

  padding: 2px 6px;
  border-radius: 8px;
}

.perpetuals-modal-token-select-back-button {
  width: 24px;
  height: 24px;

  cursor: pointer;

  transform: rotate(90deg);
}
