.perpetuals-form-summary {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;

  background: var(--secondary-02);
  border-radius: 12px;
}

.perpetuals-form-summary-item {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.perpetuals-form-summary-text-container {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.perpetuals-form-summary-text {
  width: 100%;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.perpetuals-form-summary-tooltip {
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}

.perpetuals-form-summary-skeleton {
  flex-basis: 0;
  flex-grow: 1;

  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
}
