.perpetuals-container {
  width: 100%;

  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr auto;
  margin: auto;
  max-width: 155rem;
  min-height: calc(100vh - 65px);
  padding-top: 4.65rem;
  padding: 0 32px 32px;
}

.perpetuals-primary-container {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: min-content max-content max-content;

  & > div {
    grid-column: span 1;
  }
}

.perpetuals-form-container {
  width: 400px;
  height: 692px;

  background: var(--background-01);

  border: 1px solid var(--secondary-02);
  border-radius: 12px;

  padding: 20px;
}

.perpetuals-secondary-container {
  display: grid;
  grid-template-columns: 1fr auto;
}

@media only screen and (max-width: 1280px) {
  .perpetuals-container {
    grid-template-columns: 1fr;
    padding: 0 24px 24px;
  }

  .perpetuals-secondary-container {
    grid-gap: 16px;
  }
}

@media only screen and (max-width: 920px) {
  .perpetuals-form-container {
    width: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .perpetuals-container {
    padding: 0;
    padding-bottom: 86px;
  }

  .perpetuals-form-container {
    width: auto;
    border: none;
  }

  .perpetuals-primary-container {
    grid-gap: 0;
  }

  .perpetuals-secondary-container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
