.cardContent {
  width: 100px;
  display: flex;
  flex-direction: row;
  padding: 8px 8px 8px 16px;
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-01);
}

.input {
  min-width: 0;
  background-color: transparent;
  border: none;
  color: var(--white-01);
  display: inline-flex;
  outline: none;
  font-size: var(--typography-16-medium-font-size);
  flex-grow: 4;
  flex-basis: 0;
}

.header {
  user-select: none;
}

.modal {
  height: 100%;
  max-height: 100%;
}
