.container {
  max-width: 568px;

  @media screen and (max-height: 715px) and (max-width: 767px) {
    padding: 0 12px;
    width: 100%;
    position: fixed;
    bottom: calc(1rem + 64px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }

  @media screen and (max-height: 715px) and (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.pulse-light {
  overflow: hidden;
  will-change: transform;
}

.pulse-light::after {
  content: '';
  $from: #05f283;
  $to: scale-color($from, $lightness: 20%);
  border-radius: 12px;
  background: linear-gradient(110deg, transparent 0%, $to 30%, transparent 50%);
  height: 100%;
  width: 100%;
  inset: 0;
  position: absolute;
  animation: pulse 1.8s ease-in-out infinite;

  @keyframes pulse {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }
}
