.theme-dark {
  --overlay-01: rgba(23, 23, 23, 0.9);
  --overlay-02: rgba(0, 0, 0, 0.8);
  --layout-01: rgba(11, 9, 21, 0.1973);
  --layout-02: #5100ff;
  --layout-03: #f066ff;
  --background-01: #0b0915;
  --primary-01: #05f283;
  --secondary-01: #632bdc;
  --secondary-02: #20123f;
  --secondary-03: #864df7;
  --secondary-04: #301761;
  --black-01: #000000;
  --white-01: #ffffff;
  --white-02: rgba(255, 255, 255, 0.6);
  --orange-01: #ffac4a;
  --red-01: #ef4a4a;
  --transparent: transparent;

  --gray-01: #877ca3;
  --primary-01-disabled: #096641;
  --secondary-03-disabled: rgba(60, 40, 112, 1);

  --gradient-app: linear-gradient(180deg, rgba(5, 242, 131, 0) 0%, #05f283 100%);
  --gradient-price_up: linear-gradient(180deg, rgba(5, 242, 131, 0) 0%, #05f283 100%);
  --gradient-price_down: linear-gradient(180deg, #05f283 0%, rgba(5, 242, 131, 0) 100%);
}

.theme-default {
  --typography-font-family: 'Roboto Mono', Arial, sans-serif;

  --typography-26-medium-font-size: 26px;
  --typography-26-medium-line-height: 33.8px;
  --typography-26-medium-font-weight: 500;

  --typography-22-medium-font-size: 22px;
  --typography-22-medium-line-height: 28.6px;
  --typography-22-medium-font-weight: 500;

  --typography-18-medium-font-size: 18px;
  --typography-18-medium-line-height: 23.4px;
  --typography-18-medium-font-weight: 500;

  --typography-16-medium-font-size: 16px;
  --typography-16-medium-line-height: 20.8px;
  --typography-16-medium-font-weight: 500;

  --typography-14-medium-font-size: 14px;
  --typography-14-medium-line-height: 18px;
  --typography-14-medium-font-weight: 500;

  --typography-14-regular-font-size: 14px;
  --typography-14-regular-line-height: 18px;
  --typography-14-regular-font-weight: 400;

  --typography-12-medium-font-size: 12px;
  --typography-12-medium-line-height: 15px;
  --typography-12-medium-font-weight: 500;

  --typography-12-regular-font-size: 12px;
  --typography-12-regular-line-height: 15px;
  --typography-12-regular-font-weight: 400;

  --typography-11-regular-font-size: 11px;
  --typography-11-regular-line-height: 14px;
  --typography-11-regular-font-weight: 400;

  --typography-10-medium-font-size: 10px;
  --typography-10-medium-line-height: 15px;
  --typography-10-medium-font-weight: 500;
}
